


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

import { mapGetters } from 'vuex';
import _cloneDeep from 'lodash.clonedeep';
import { TContact } from '@/_types/contact.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TMessage } from '@/_types/messages.type';
import { Moment } from 'moment';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import { TStoreEntityState } from '@/_types/store/store-entity-state.type';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {Action, Getter} from 'vuex-class';

type TDisplayedMessage = TMessage & {
  isDayTitleShown: boolean;
}

const DIRECT_MESSAGE_MAX_LENGTH = 1000;
const MESSAGES_PAGE_LENGTH = 50; // TODO: use imported

@Component({
  components: {
    IconArrowLeft
  },
  computed: {
    ...mapGetters({
      user: '_userStore/user',
      event: '_eventStore/event',
      promoPage: 'promoPageStore/promoPage',
      isAuthenticated: 'authStore/isAuthenticated',
      getMessagesStateByUserId: 'messagesStore/getMessagesStateByUserId',
      myself: 'promoPageStore/contact',
    }),
  },
})
export default class Messages extends Vue {

  @Getter('meetingsStore/meetingInviteUrlForChatMessage') meetingInviteUrlForChatMessage: string;
  @Action('meetingsStore/clearMeetingInviteUrlForChatMessage') clearMeetingInviteUrlForChatMessage: () => void;

  @Prop({ type: Number, default: 0 })
  public readonly contactId: number;

  @Prop({ type: Object, default: null })
  public readonly currentContact: TContact;

  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly promoPage: TPromoPage;
  public readonly myself: TContact;
  public readonly isAuthenticated: boolean;
  public readonly getMessagesStateByUserId: (userId: number) => TStoreEntityState<TApiListResponse<TMessage>>;

  public newMessageText: string = '';
  public isFieldWrapFocused: boolean = false;
  public messageListHasBeenInitialized: boolean = false;
  public isMessageListLoadingMore: boolean = false;
  public isNewMessageTextareaTransitioning: boolean = false;

  public created(): void {
    this.loadMessageList();
    this.useMeetingInviteUrl();
  }

  public useMeetingInviteUrl(): void {
    if (this.meetingInviteUrlForChatMessage) {
      this.newMessageText = this.meetingInviteUrlForChatMessage;
    }
    this.clearMeetingInviteUrlForChatMessage();
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get userId(): number {
    return (this.currentContact && this.currentContact.user && this.currentContact.user.id) || null;
  }

  public get messagesState(): TStoreEntityState<TApiListResponse<TMessage>> {
    return this.getMessagesStateByUserId(this.userId);
  }

  public get messages(): TMessage[] {
    const messagesState = this.messagesState;
    return (messagesState && messagesState.data && messagesState.data.List) || null;
  }

  public get isDirectMessageLengthExceeded(): boolean {
    return this.newMessageText.length >= DIRECT_MESSAGE_MAX_LENGTH;
  }

  public get newMessageTextProcessed(): string {
    let result: string = this.newMessageText;
    // escape html
    result = result.replace('<', '&lt;');

    // nl2br
    result = result.replace(/\n/g, '<br>');

    return result;
  }

  public get isSendMessageButtonDisabled(): boolean {
    return this.isDirectMessageLengthExceeded || (this.newMessageText === '');
  }

  public get userMessagesPrepared(): TDisplayedMessage[] {
    // TODO: tidy up
    const result: TDisplayedMessage[] = [];
    const messages = this.messages;
    if (!messages) {
      return [];
    }
    let previousMessage: TDisplayedMessage = null;
    messages.forEach((message: TMessage) => {
      let isDayTitleShown: boolean;
      if (!previousMessage) {
        isDayTitleShown = true;
      } else {
        const prevMessageMoment = this.$moment(previousMessage.created_at) as Moment;
        const iteratedMessageMoment = this.$moment(message.created_at) as Moment;
        isDayTitleShown = iteratedMessageMoment.diff(prevMessageMoment, 'days') > 0;
      }
      const processedMessage: TDisplayedMessage = _cloneDeep({
        ...message,
        isDayTitleShown
      });
      result.push(processedMessage);
      previousMessage = _cloneDeep(processedMessage);
    });
    return result;
  }

  private async loadMessageList(scrollAfterResponse: boolean = true): Promise<void> {

    const userId = this.userId;

    // TODO: recheck. refactor.

    // If on first open we already have messages from store, don't re-request.
    if (!this.messageListHasBeenInitialized && this.messages && this.messages.length) {
      this.messageListHasBeenInitialized = true;
      this.$nextTick(this.scrollMessagesToBottom);
      this.markNoticedMessages();
      return;
    }

    await this.$store.dispatch('messagesStore/requestUserMessages', {
      userId,
      limit: MESSAGES_PAGE_LENGTH,
      offset: this.messages ? this.messages.length : 0,
    });

    if (scrollAfterResponse) {
      this.scrollMessagesToBottom();
    }

    this.markNoticedMessages();

    // for ps-y-reach-start not to work when the list is empty
    this.messageListHasBeenInitialized = true;

  }

  public isMessageMine(message: TMessage): boolean {
    return message.is_mine;
  }

  private getFormattedMessageDayTitle(message: TMessage): string {
    return this.$moment(message.created_at).format('dddd, MMMM Do YYYY');
  }

  public getFormattedMessageDayTime(message: TMessage): string {
    return DateTimeHelper.getHoursMinutes(new Date(message.created_at));
  }

  private async sendMessage(): Promise<void> {
    if (this.newMessageText && this.newMessageText.trim() && !this.isDirectMessageLengthExceeded) {

      await this.$store.dispatch('messagesStore/sendMessage', {
        eventId: this.eventId,
        userId: this.currentContact.user.id,
        text: this.newMessageText.trim()
      });

      this.createAndDisplayNewOwnMessage(this.newMessageText.trim());
      this.newMessageText = '';
    }
  }

  private createAndDisplayNewOwnMessage(messageText: string): void {
    if (!messageText) {
      return;
    }

    const temporaryId: number = this.messages ? -1 - this.messages.filter(msg => msg.id < 0).length : -1;
    const message: TMessage = {
      id: temporaryId,
      text: messageText,
      created_at: (new Date()).toUTCString(),
      is_read: true,
      is_mine: true,
      contact: this.currentContact,
      recipient: this.currentContact,
      sender: this.myself,
    };

    this.$store.dispatch('messagesStore/pushMessage', { message });
    this.$nextTick(this.scrollMessagesToBottom);
  }

  private isMessageLast(messageIndex: number): boolean {
    return messageIndex === this.userMessagesPrepared.length - 1;
  }

  private scrollMessagesToBottom(): void {
    if (!this.$refs.messagesListScroll) {
      return;
    }
    const messagesListScroller = (this.$refs.messagesListScroll as Vue).$el as HTMLElement;
    messagesListScroller.scrollTop = messagesListScroller.scrollHeight;
  }

  // TODO: refactor
  private markNoticedMessages(): void {
    const userMessagesPrepared = this.userMessagesPrepared;
    if (!userMessagesPrepared || !this.myself) {
      window.setTimeout(() => {
        this.markNoticedMessages();
      }, 250);
      return;
    }
    const messageIds: number[] = userMessagesPrepared.map((message: TDisplayedMessage) => message.id);
    this.$store.dispatch('notificationsStore/setNoticedMessages', messageIds);
    this.$store.dispatch('contactsStore/clearContactUnreadMessages', this.myself.id);
  }

  private handleFieldWrapClick(): void {
    if (!this.$refs.newMessageTextarea) {
      return;
    }
    (this.$refs.newMessageTextarea as HTMLTextAreaElement).focus();
  }

  private setFieldWrapFocused(): void {
    this.isFieldWrapFocused = true;
  }

  private setFieldWrapUnfocused(): void {
    this.isFieldWrapFocused = false;
  }

  private async loadMoreMessages(): Promise<void> {
    const totalMessages: number = (this.messagesState && this.messagesState.data && this.messagesState.data.Total) ? this.messagesState.data.Total : null;
    if (!this.messageListHasBeenInitialized || this.isMessageListLoadingMore) {
      return;
    }
    if (totalMessages && this.messages && (this.messages.length >= totalMessages)) {
      return;
    }
    this.isMessageListLoadingMore = true;
    await this.loadMessageList(false);
    this.isMessageListLoadingMore = false;
  }

  private getTextareaAutoHeight(): string {

    const defaultHeight = '14px';
    let result = defaultHeight;
    const heightMonitor: HTMLDivElement = this.$refs.newMessageTextAutoHeightDouble as HTMLDivElement;
    if (!heightMonitor || this.newMessageText === '') {
      return result;
    }

    const lineBreakHeight: number = this.newMessageText.split(/\n/).length * 14; // 14 is line-height.
    const boundingHeight: number = heightMonitor.getBoundingClientRect().height;

    result = Math.max(lineBreakHeight, boundingHeight).toFixed(0);

    if (result !== '0') {
      result = result + 'px';
    } else {
      result = defaultHeight;
    }
    return result;
  }

  private handleNewMessageTextareaTransitionStart(): void {
    this.isNewMessageTextareaTransitioning = true;
  }

  private handleNewMessageTextareaTransitionEnd(): void {
    this.isNewMessageTextareaTransitioning = false;
  }

  private getNewMessageTextareaClasses(): any {
    return {
      'is-transitioning': this.isNewMessageTextareaTransitioning,
      'is-over-height-limit': parseInt(this.getTextareaAutoHeight(), 10) >= 14 * 8,
      'is-empty': this.newMessageText === '',
    };
  }

  public generateLinksWithImagePreviews(text: string): string {
    return ChatHelper.createLinksWithImagePreviews(text);
  }

}
