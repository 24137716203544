var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.contactDescription || _vm.joinedOn)?_c('div',{staticClass:"tab-content-section"},[_c('div',{staticClass:"tab-content-section-title"},[_vm._v(" "+_vm._s(_vm.$t('contacts.popups.editContact.form_sections.info'))+" ")]),_c('div',{staticClass:"joined-on"},[_vm._v(_vm._s(_vm.$t('Join_at'))+" "+_vm._s(_vm.joinedOn))]),_c('div',{staticClass:"tab-content-section-content",domProps:{"innerHTML":_vm._s(_vm.contactDescription)}})]):_vm._e(),(_vm.contactFiles.length)?_c('div',{staticClass:"tab-content-section"},[_c('div',{staticClass:"tab-content-section-title"},[_vm._v(" "+_vm._s(_vm.$t('title.documents'))+" ")]),_c('div',{staticClass:"tab-content-section-content"},[_c('ew-document-list',{attrs:{"files":_vm.contactFiles}})],1)]):_vm._e(),(_vm.announcementList.length)?_c('div',{staticClass:"tab-content-section"},[_c('div',{staticClass:"tab-content-section-title"},[_vm._v(" "+_vm._s(_vm.$t('title.notes'))+" ")]),_c('div',{staticClass:"tab-content-section-content"},_vm._l((_vm.announcementList),function(announcement){return _c('div',{staticClass:"announcement"},[_c('person',{attrs:{"contact":_vm.contact,"person-type":"small"}}),_c('div',{staticClass:"announcement-head"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"announcement-date"},[_vm._v(" "+_vm._s(_vm.getAnnouncementDate(announcement))+" ")]),_c('div',{staticClass:"announcement-title"},[_vm._v(" "+_vm._s(announcement.title)+" ")])]),_c('div',{staticClass:"announcement-actions"},[_c('div',{staticClass:"action"},[_c('span',{class:{
                  'checked': announcement.is_favorite,
                  'unchecked': !announcement.is_favorite
                },on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavoriteAnnouncement(announcement)}}},[(announcement.is_favorite)?_c('iconFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('button.rm_favorites'),
                    classes: ['vue-tooltip-dark'],
                    placement: 'bottom',
                    delay: 0,
                  }),expression:"{\n                    content: $t('button.rm_favorites'),\n                    classes: ['vue-tooltip-dark'],\n                    placement: 'bottom',\n                    delay: 0,\n                  }"}]}):_c('iconUnFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('button.favorites'),
                    classes: ['vue-tooltip-dark'],
                    placement: 'bottom',
                    delay: 0,
                  }),expression:"{\n                    content: $t('button.favorites'),\n                    classes: ['vue-tooltip-dark'],\n                    placement: 'bottom',\n                    delay: 0,\n                  }"}]})],1)]),_c('div',{staticClass:"action"},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.shareAnnouncement($event)}}},[_c('iconShare')],1)])])]),_c('div',{staticClass:"note-body",domProps:{"innerHTML":_vm._s(_vm.linkifyUrls(announcement.text))}})],1)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }